<template>
  <div>
    <b-card class="mp-0">

      <router-link to='/locum'><b-button  class="shadow-sm font-weight-bold "
                                          variant="white"><feather-icon icon="ChevronLeftIcon" size="20"/></b-button></router-link>
      <span class="font-weight-700"> Profile </span>


       <b-overlay
        :show="tableLoading"
        rounded="sm"
        >
      <b-row>

        <b-col
            md="6"
            xl="4"
            class="mb-1 mt-1"
        >
          <b-form-group
              label="First Name"
              label-for="First Name"
          >
            <b-form-input
                v-model="first_name"
                readonly
            />
          </b-form-group>
        </b-col>

        <b-col
            md="6"
            xl="4"
            class="mb-1 mt-1"
        >
          <b-form-group
              label="Last Name"
              label-for="Last Name"
          >
            <b-form-input
                v-model="last_name"
                readonly
            />
          </b-form-group>
        </b-col>

        <b-col
            md="6"
            xl="4"
            class="mt-1"
        >
          <b-form-group
              label="Role"
              label-for="readOnlyInput"
          >
            <b-form-input
                v-model="role"
                readonly
            />
          </b-form-group>
        </b-col>
        <b-col
            md="6"
            xl="4"
        >
          <b-form-group
              label="Telephone Number"
              label-for="readOnlyInput"
          >
            <b-form-input
                v-model="telephone_no"
                readonly
            />
          </b-form-group>
        </b-col>

        <b-col
            md="6"
            xl="4"
        >
          <b-form-group
              label="Email"
              label-for="readOnlyInput"
          >
            <b-form-input
                v-model="email"
                readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
       </b-overlay>
    </b-card>

      <b-card>
      <span class="font-weight-700"> Documents </span>
      <template>
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
        <b-table
            :current-page="currentPage"
            :fields="fields"
            :items="underReviewDocuments"
            hover
            ref="table"

            class="mobile_table_css"
            responsive
        >

          <template #cell(name)="data">
            <b-col>
              <b-media no-body >
                <b-img
                    :src="require(`@/assets/images/filetypes/${data.item.mime_type.split('/')[1]}.png`)"
                    alt=""
                    class="mr-1 "
                    height="30"
                />
                <h6 class="align-self-center my-auto font-weight-bolder">
                  {{ data.item.name}}
                </h6>
              </b-media>
            </b-col>
          </template>

          <template #cell(type)="data" >
            {{makeUpperCase(data.item.type)}}
          </template>

          <template #cell(created_at)="data" >
            {{ momentFormat(data.item.created_at, 'dddd DD - MM - YYYY') }}
          </template>

          <template #cell(state)="data">
            <div v-if="makeUpperCase(data.item.state)==='APPROVED'">
              <b-badge  class="width-100 bg-success"
                     style="padding:8px">
              <span class="">  {{ makeUpperCase(data.item.state) }} </span>
            </b-badge>
            </div>
            <div v-if="makeUpperCase(data.item.state)==='DECLINED'">
              <b-badge  class="width-100 bg-danger"
                     style="padding:8px">
              <span class="">  {{ makeUpperCase(data.item.state) }} </span>
            </b-badge>
            </div>
            <div v-if="makeUpperCase(data.item.state)==='UNDER REVIEW'">
              <b-badge  class="width-100 bg-warning"
                     style="padding:8px">
              <span class="">  {{ makeUpperCase(data.item.state) }} </span>
            </b-badge>
            </div>
<!--            :variant="getStatusWithColor(data.item.state).variant"-->
            
          </template>

          <template #cell(download)="data" >
            <b-button @click="downloadDocument(data.item.locum_id, data.item.id, data.item.name)"
                v-b-tooltip.hover.top="'Download'"

                size="23"
                variant="outline-white"
                class="btn-icon bg-white text-primary m-1"
            >
              <feather-icon icon="DownloadIcon" size="18" />
            </b-button>
          </template>

            <template #cell(approve)="data" >
            <b-button v-if="data.item.state != 'approved' && data.item.state != 'not mandatory'" @click.prevent="approveDocument(data.item.locum_id,data.item.id)"
                v-b-tooltip.hover.top="'Approve'"

                size="23"
                variant="outline-white"
                class=" bg-white text-success m-1"
            >
              <feather-icon icon="CheckSquareIcon" size="18" />
            </b-button>
            </template>

          <template #cell(decline)="data">
            <b-button v-if="data.item.state != 'declined' && data.item.state != 'not mandatory'" @click="declineDocument(data.item.locum_id,data.item.id)"
                v-b-tooltip.hover.top="'decline'"

                size="23"
                variant="outline-white"
                class=" bg-white text-danger m-1"
            >
              <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
            </b-button>
          </template>

        </b-table>
        </b-overlay>
      </template>
    </b-card>
  </div>

</template>



<script>
import {BFormInput, BRow, BCol, BFormGroup, BCard, BBadge, BButton,BTable,BImg,BMedia,BOverlay,VBTooltip} from 'bootstrap-vue'
import ApiLocum from "@/apis/modules/locums";
import MomentMixin from '@/mixins/MomentMixin'
import axios from 'axios'
import {apiUrl} from '@/constants/config'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BTable,
    BImg,
    BMedia,
    BOverlay,
    VBTooltip
  },
  mixins: [MomentMixin],

  directives: {
    'b-tooltip': VBTooltip
  },

  data() {
    return {
      tableLoading: false,
      underReviewLocums: [],
      underReviewDocuments: [],
      first_name: "",
      last_name: "",
      // gender: "",
      role: "",
      telephone_no: "",
      email: "",

      fields: [
        {
          key: 'type',
          label: 'DOCUMENT TYPE',
        },
        {
          key: 'name',
          label: 'DOCUMENT NAME',
        },
        {
          key: 'state',
          label: 'STATE',
        },
        {
          key: 'created_at',
          label: 'DATE UPLOADED',
        },
        {
          key: 'download',
          label: '',
        },
        {
          key: 'approve',
          label: '',
        },
        {
          key: 'decline',
          label: '',
        },
      ],

      currentPage : 1
    }
  },

  methods: {
    async getLocums() {
      try {
        this.tableLoading = false
        this.underReviewLocums = (await ApiLocum.getLocum(this.$route.params.id)).data
        this.first_name = this.underReviewLocums.user.first_name;
        this.last_name = this.underReviewLocums.user.last_name;
        // this.gender = this.underReviewLocums.user.first_name;
        this.role = this.underReviewLocums.job_role.name;
        this.telephone_no = this.underReviewLocums.user.phone_number;
        this.email = this.underReviewLocums.user.email;

        // this.tableLoading = true
        if (this.underReviewLocums) {
          this.underReviewDocuments = this.underReviewLocums.files;
          // console.log(this.underReviewDocuments)
          // console.log(this.underReviewDocuments)
          this.tableLoading = false

        }
      } catch (e) {
        this.tableLoading = false
      }
    },


    async approveDocument(locumId,documentId) {
      // console.log(id,locumId)
      try {
        this.tableLoading = false
        const response = (await ApiLocum.approveLocum(locumId,documentId))
        // console.log(response) locumId
        this.showSuccessMessage('Document approved')
        this.tableLoading = false
        await this.getLocums()
      }catch (e) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

    async declineDocument(locumId,documentId) {
      try {
        this.tableLoading = false
        const Response = (await ApiLocum.declineLocum(locumId,documentId))
        // console.log(response)
        this.showSuccessMessage('Document declined')
        this.tableLoading = false
        await this.getLocums()
      }catch (e) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },


     downloadDocument(locumId,documentId, documentName) {
       try {
         this.tableLoading = true
         axios
             .get(`${apiUrl}api/super-admin/locums/${locumId}/documents/${documentId}/show`, {
               // headers{
               // }
               responseType: 'arraybuffer',
               withCredentials: true
             })
             .then(response => {

               let blob = new Blob([response.data], {type: 'application/pdf'}),
                   // url = window.URL.createObjectURL(blob)
                   // let blob = new Blob([response.data], { type: 'application/pdf' })
                   link = document.createElement('a')
               link.href = window.URL.createObjectURL(blob)
               link.download = documentName
               link.click()
             })
         this.showSuccessMessage('File Download Success full')
         this.tableLoading = false
       } catch (error) {
         this.convertAndNotifyError(error)
         this.tableLoading = false
       }

    },



  },

  async mounted() {
    await this.getLocums()
        // await this.getDocuments()
    // console.log(this.$route.params)
  }
}
</script>

<style>

</style>
